import { Options, Vue } from 'vue-class-component'
import Api from '@/api/index'
import Utils from '@/utils'
import store from '@/store'
import { SuperVue } from '@/mixins/SuperVue'

@Options({
  components: {}
})

export default class MessageCenter extends SuperVue {
  created() {
    this.getMessageByToken()
    this.getUser()
  }

  getMessageByToken() {
    Api.http_getMessageByToken({
      offset: 0,
      length: 9999
    }).then(res => {
      this.messageList[0] = res.data.data
      this.messageList[1] = res.data.data.filter(item => item.is_read === 1)
      this.messageList[2] = res.data.data.filter(item => item.is_read === 0)
      store.commit('setUnReadCount', this.messageList[2].length)
    })
  }
  setReader(id:number) {
    Api.http_setReader({ id: id }).then(res => {
      if (res.code === '200') this.getMessageByToken()
    })
  }
  curMessageIdx: string = 'name0'
  messageList: MessageList = [[], [], []]

  user: any = {}
  getUser() {
    Api.http_personal_center_getUser({}).then(res => {
      if (res.code === '200') {
        this.user = res.data;
        this.user.roles = this.user.roles.split(',');
        if (Utils.isJSON(this.user.patent_file)) { this.user.patent_file = JSON.parse(this.user.patent_file) }
        if (Utils.isJSON(this.user.market_file)) { this.user.market_file = JSON.parse(this.user.market_file) }
        this.user.birthday = Utils.getIdCardInfo(this.user.id_number, 1)
        this.user.roles = this.user.roles.split(',')
      }
    })
  }
  navTo(msgItem) {
    if (msgItem.is_read === 0) {
      this.setReader(msgItem.id)
    }
    let roleId = null

    store.state.originAccess.some(ele => {
      return ele.access.some(accessItem => {
        if (accessItem.name === msgItem.url) {
          store.dispatch('SETACCESS', { access: ele.access, id: ele.id })
          roleId = ele.id
          return true
        }
      })
    });

    if (roleId !== null && msgItem.url !== null) {
      this.$router.push({ name: msgItem.url })
    } else {
      this.$message.warning('您暂无本页面处理权限,请联系管理员!')
    }
  }
}

type MessageList = {
  id: number
  created_at: string;
  title: string;
  content: string;
  is_read: number;
  sender: string;
}[][]
